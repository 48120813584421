@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'BoschSans';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/font/BoschSans-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'BoschSansBold';
  font-style: normal;
  font-weight: bold;
  src: url(./assets/font/BoschSans-Bold.otf) format('opentype');
}

.truncate-3lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
